import LoadingComponent from "../../app/components/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { Box, Paper, Grid, Typography } from "@mui/material";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { useAppSelector } from "../../app/store/configureStore";
import { DirectDebitPaymentIntent } from "../../app/models/directDebitRun/directDebitPaymentIntent";
import { useParams } from "react-router-dom";
import { useGetDirectDebitPaymentIntents } from "../../app/customHooks/DirectDebitPaymentIntent/useGetDirectDebitPaymentIntents";
import { useEffect, useState } from "react";
import { formatDateWithTime } from "../../app/utils/util";

export default function DirectDebitPaymentIntents() {

    const { directDebitRunId } = useParams<{ directDebitRunId: string }>();

    useGetDirectDebitPaymentIntents();

    const { directDebitsRun, directDebitsRunStatus } = useAppSelector(state => state.directDebitRun);

    const [directDebitPaymentIntents, setDirectDebitPaymentIntents] = useState<DirectDebitPaymentIntent[]>([]);

    useEffect(() => {
        const paymentIntentList = directDebitsRun?.find(x => x.id === directDebitRunId)?.paymentIntents;
        if (paymentIntentList && paymentIntentList?.length > 0) {
            setDirectDebitPaymentIntents(paymentIntentList);
        }
    }, [directDebitsRun, directDebitRunId]);

    if (directDebitsRunStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box component={Paper} sx={{ padding: 3 }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}>
                    <Typography variant='h4'>Payment intents</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid container sx={{
                    backgroundColor: '#616161',
                    color: "#fff",
                    padding: 2,
                    textAlign: 'left'
                }}>
                    <Grid item xs={0.5}></Grid>

                    <Grid item xs={3.5}>
                        Reference
                    </Grid>

                    <Grid item xs={2}>
                        Amount
                    </Grid>

                    <Grid item xs={3}>
                        Name
                    </Grid>

                    <Grid item xs={2}>
                        Collection due date
                    </Grid>

                    <Grid item xs={1}></Grid>
                </Grid>

                {(directDebitPaymentIntents === undefined || directDebitPaymentIntents == null ||
                    directDebitPaymentIntents?.filter(x => x.directDebitRunId === directDebitRunId)?.length === 0) &&
                    <Grid container sx={{
                        textAlign: 'left',
                        alignItems: 'center'
                    }} rowSpacing={2}>

                        <Grid item xs={12}></Grid>
                        <br />

                        <Grid item xs={12} sx={{
                            textAlign: 'center'
                        }}>
                            No payment intents found.
                        </Grid>

                        <Grid item xs={12}></Grid>

                    </Grid>
                }

                {directDebitPaymentIntents?.filter(x => x.directDebitRunId === directDebitRunId).map((item: DirectDebitPaymentIntent, index: number) => (
                    <Grid container key={index} sx={{
                        textAlign: 'left',
                        alignItems: 'center'
                    }} rowSpacing={2}>

                        <Grid item xs={12}></Grid>

                        <Grid item xs={0.5}></Grid>

                        <Grid item xs={3.5}>
                            {item.reference}
                        </Grid>

                        <Grid item xs={2}>
                            {item.amount}
                        </Grid>

                        <Grid item xs={3}>
                            {item.mandate ? (item.mandate?.firstName + ' ' + item.mandate?.surname)
                                : ''}
                        </Grid>

                        <Grid item xs={2}>
                            {item?.collectionDueDate && formatDateWithTime(new Date(item?.collectionDueDate))}
                        </Grid>

                        <Grid item xs={1}></Grid>

                    </Grid>
                ))}

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}
