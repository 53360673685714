import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { ApiRequestStatus } from '../../enums/apiRequestStatus';
import { getDirectDebitPaymentIntentsAsync } from '../../slices/directDebitPaymentIntentSlice';

export const useGetDirectDebitPaymentIntents = () => {
    const dispatch = useAppDispatch();
    const { directDebitPaymentIntents, directDebitPaymentIntentStatus } = useAppSelector(state => state.directDebitPaymentIntent);

    useEffect(() => {
        if (directDebitPaymentIntents === null && directDebitPaymentIntentStatus === ApiRequestStatus.Idle) {
            dispatch(getDirectDebitPaymentIntentsAsync());
        }
    }, [directDebitPaymentIntents, directDebitPaymentIntentStatus, dispatch]);

    return { directDebitPaymentIntentStatus };
};
