import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import { documentSlice } from "../slices/documentSlice";
import { referenceDataSlice } from "../slices/referenceDataSlice";
import { manualPaymentSlice } from "../slices/manualPaymentSlice";
import { famSlice } from "../slices/famSlice";
import { personSlice } from "../slices/personSlice";
import { directDebitMandateSlice } from "../slices/directDebitMandateSlice";
import { directDebitRunSlice } from "../slices/directDebitRunSlice";
import { directDebitPaymentIntentSlice } from "../slices/directDebitPaymentIntentSlice";

export const store = configureStore({
    reducer: {
        document: documentSlice.reducer,
        referenceData: referenceDataSlice.reducer,
        manualPayment: manualPaymentSlice.reducer,
        famDetails: famSlice.reducer,
        person: personSlice.reducer,
        directDebitMandate: directDebitMandateSlice.reducer,
        directDebitRun: directDebitRunSlice.reducer,
        directDebitPaymentIntent: directDebitPaymentIntentSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;