import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import AppSelectList from "../../app/components/AppSelectList";
import { MapFundsToDropdownItems, MapPaymentFrequencyToDropdownItems, MapProductsToDropdownItems, MapTypeOfDirectDebitsToDropdownItems } from "../../app/slices/convertStaticDataToDropdownItems";
import { FormProvider, useForm } from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { DirectDebitValidationSchemas } from "../../app/models/directDebit/directDebitValidationSchemas";
import { useEffect, useState } from "react";
import DirectDebitFormModel from "../../app/models/directDebit/directDebitFormModel";
import { DirectDebitTypeEnum } from "../../app/enums/directDebitTypeEnum";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { PaymentTypes } from "../../app/enums/paymentTypes";
import { ProductDto } from "../../app/models/referenceData/productDto";
import { getPersonalDetailsAsync } from "../../app/slices/personSlice";
import { EntityTypeEnum } from "../../app/enums/entityTypeEnum";
import { PaymentFrequencyEnum } from "../../app/enums/paymentFrequencyEnum";
import { submitDonationDirectDebitAsync, submitFamDirectDebitAsync } from "../../app/slices/directDebitMandateSlice";
import { CreateDonationDirectDebitMandateCommand } from "../../app/models/directDebit/createDonationDirectDebitMandateCommand";
import { CreateFamDirectDebitMandateCommand } from "../../app/models/directDebit/createFamDirectDebitMandateCommand";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { CurrencyCodeEnum } from "../../app/enums/currencyCodeEnum";
import SuccessResponseMessage from "../ManualPayment/common/SuccessResponseMessage";
import { getFamDetailsAsync } from "../../app/slices/famSlice";
import { referenceDataAsync } from "../../app/slices/referenceDataSlice";
import { yesNoDropDownValues } from "../../app/utils/util";

export default function DirectDebitForm() {
    const queryParams = new URLSearchParams(window.location.search);
    const customerId = queryParams.get("customerId");
    const entityType = queryParams.get("entityType");

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(DirectDebitValidationSchemas)
    });

    const formObj = methods.watch();

    const { control, trigger, watch, getValues, setValue, reset, formState: { isDirty } } = methods;
    const [typeOfDirectDebit, setTypeOfDirectDebit] = useState('');
    const [filteredProductTypes, setFilteredProductTypes] = useState<ProductDto[]>([]);

    const dispatch = useAppDispatch();
    const { productTypes, funds, currencies } = useAppSelector(state => state.referenceData);
    const { famDetails } = useAppSelector(state => state.famDetails);
    const { personalDetails, personalDetailsStatus } = useAppSelector(state => state.person);
    const { reference, submitDonationDirectDebitDetailsStatus, submitFamDirectDebitDetailsStatus } = useAppSelector(state => state.directDebitMandate);

    const {
        formField: {
            ProductType,
            FirstName,
            Surname,
            FullName,
            Fund,
            BIC,
            IBAN,
            TypeOfDirectDebit,
            PaymentFrequency,
            Amount,
            SupressPaymentIntentCreation
        }
    } = DirectDebitFormModel;

    useEffect(() => {
        if (customerId) {
            dispatch(referenceDataAsync());
            dispatch(getFamDetailsAsync(customerId));
            dispatch(getPersonalDetailsAsync(customerId));
        }
    }, [dispatch, customerId]);

    const MapToCreateDonationDirectDebitMandateDto = () => {
        const paymentFrequency = formObj.PaymentFrequency === PaymentFrequencyEnum.Monthly ? "MONTH" :
            (formObj.PaymentFrequency === PaymentFrequencyEnum.Every3Months ? "MONTH" : "YEAR");
        const intervalCount = formObj.PaymentFrequency === PaymentFrequencyEnum.Every3Months ? 3 : 1;

        const data: CreateDonationDirectDebitMandateCommand = {
            personId: customerId ?? '',
            firstName: formObj.FirstName,
            surname: formObj.Surname,
            IBAN: formObj.IBAN,
            BIC: formObj.BIC,
            Recurring: formObj.TypeOfDirectDebit.toLowerCase() === DirectDebitTypeEnum.RECURRENT.toLowerCase() ? true : false,
            IntervalUnitCode: paymentFrequency,
            IntervalCount: intervalCount,
            FundId: getValues(Fund.name),
            Amount: formObj.Amount,
            UserId: customerId ?? ''
        }
        return data;
    }

    const MapToCreateFamDirectDebitMandateDto = () => {
        const data: CreateFamDirectDebitMandateCommand = {
            personId: customerId ?? '',
            firstName: formObj.FirstName,
            surname: formObj.Surname,
            IBAN: formObj.IBAN,
            BIC: formObj.BIC,
            Recurring: true,
            IntervalUnitCode: PaymentFrequencyEnum.Year.toUpperCase(),
            IntervalCount: 1,
            NoPaymentIntent: formObj.SupressPaymentIntentCreation.toLowerCase() === "yes",
            UserId: customerId ?? ''
        }
        return data;
    }

    const payDirectDebit = async () => {
        const isStepValid = await trigger();

        if (isStepValid && customerId !== null && isDirty === true) {
            if (getValues(ProductType.name) === PaymentTypes.DONATION) {
                dispatch(submitDonationDirectDebitAsync(MapToCreateDonationDirectDebitMandateDto()));
            }
            else if (getValues(ProductType.name) === PaymentTypes.FAM_ANUAL_MEMBERSHIP) {
                dispatch(submitFamDirectDebitAsync(MapToCreateFamDirectDebitMandateDto()));
            }
        }
    }

    useEffect(() => {
        if (customerId !== null && reference !== '' &&
            (submitDonationDirectDebitDetailsStatus === ApiRequestStatus.Fulfilled ||
                submitFamDirectDebitDetailsStatus === ApiRequestStatus.Fulfilled)) {
            dispatch(getPersonalDetailsAsync(customerId));
        }
    }, [reference, submitDonationDirectDebitDetailsStatus, submitFamDirectDebitDetailsStatus]);

    useEffect(() => {
        if (productTypes && productTypes !== null && productTypes.length > 0) {
            let productTypeList: ProductDto[] = [];
            let donationProduct = productTypes.find(x => x.code?.toUpperCase() === PaymentTypes.DONATION);
            donationProduct && productTypeList.push(donationProduct);

            if (entityType?.toLowerCase() !== EntityTypeEnum.ACCOUNT.toLowerCase() &&
                famDetails !== null && famDetails.famDetailsId !== null) {
                let anualMembershipProduct = productTypes.find(x =>
                    x.code?.toUpperCase() === PaymentTypes.FAM_ANUAL_MEMBERSHIP);
                anualMembershipProduct && productTypeList.push(anualMembershipProduct);
            }

            setFilteredProductTypes(productTypeList);
        }
    }, [productTypes, famDetails, entityType]);

    useEffect(() => {
        watch((_, { name, type }) => {
            if (name === TypeOfDirectDebit.name && type === 'change') {
                setTypeOfDirectDebit(getValues(TypeOfDirectDebit.name));
            }
        });
    }, [TypeOfDirectDebit.name, watch]);

    useEffect(() => {
        if (filteredProductTypes.length > 0) {
            if (famDetails !== null && famDetails.famDetailsId === null) {
                setValue(ProductType.name, filteredProductTypes?.find(x => x.code === PaymentTypes.DONATION)?.code);
            }
            else {
                setValue(ProductType.name, filteredProductTypes?.find(x => x.code === PaymentTypes.FAM_ANUAL_MEMBERSHIP)?.code);
            }
        }
    }, [filteredProductTypes, famDetails]);

    useEffect(() => {
        reset({
            [FirstName.name]: personalDetails?.firstName,
            [Surname.name]: personalDetails?.lastName,
            [SupressPaymentIntentCreation.name]: 'No'
        })
    }, [personalDetails]);

    if (personalDetailsStatus === ApiRequestStatus.Pending ||
        submitDonationDirectDebitDetailsStatus === ApiRequestStatus.Pending ||
        submitFamDirectDebitDetailsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (submitDonationDirectDebitDetailsStatus === ApiRequestStatus.Fulfilled ||
        submitFamDirectDebitDetailsStatus === ApiRequestStatus.Fulfilled) {
        return <SuccessResponseMessage header={'Thank you'} body={`Direct debit created successfully..`} />
    }

    return <FormProvider {...methods}>
        <Box sx={{ width: '100%' }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <AppSelectList control={control} name={ProductType.name} label={ProductType.label}
                        items={MapProductsToDropdownItems(filteredProductTypes?.filter(x => x.visible === true) ?? [])} />
                </Grid>

                {getValues(ProductType.name) === PaymentTypes.DONATION &&
                    <Grid item xs={12}>
                        <AppSelectList control={control} name={Fund.name} label={Fund.label}
                            items={MapFundsToDropdownItems(funds?.filter(x => x.currencyId === currencies?.find(y => y.code ===
                                CurrencyCodeEnum.EUR)?.id && x.active === true) ?? [])} />
                    </Grid>
                }

                <Grid item xs={12}>
                    <AppTextInput control={control} name={FirstName.name} label={FirstName.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={Surname.name} label={Surname.label} />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        {FullName.information_text}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={BIC.name} label={BIC.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={IBAN.name} label={IBAN.label} />
                </Grid>

                {getValues(ProductType.name) === PaymentTypes.DONATION && <>
                    <Grid item xs={12}>
                        <AppSelectList
                            control={control}
                            name={TypeOfDirectDebit.name}
                            label={TypeOfDirectDebit.label}
                            items={MapTypeOfDirectDebitsToDropdownItems()}
                        />
                    </Grid>

                    {typeOfDirectDebit === DirectDebitTypeEnum.RECURRENT &&
                        <Grid item xs={12}>
                            <AppSelectList
                                control={control}
                                name={PaymentFrequency.name}
                                label={PaymentFrequency.label}
                                items={MapPaymentFrequencyToDropdownItems()}
                            />
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={Amount.name} label={Amount.label} />
                    </Grid>
                </>}

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={SupressPaymentIntentCreation.name}
                        label={SupressPaymentIntentCreation.label}
                        items={yesNoDropDownValues()}
                    />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8.5}></Grid>

                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={payDirectDebit}>
                        Submit
                    </Button>
                </Grid>

                <Grid item xs={2.5}></Grid>
            </Grid>
        </Box>
    </FormProvider>
}
