const DirectDebitFormModel = {
  formField: {
    ProductType: {
      name: 'ProductType',
      label: 'Product type*',
      requiredErrorMsg: 'Product type is required.'
    },
    FirstName: {
      name: 'FirstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required.'
    },
    Surname: {
      name: 'Surname',
      label: 'Surname*',
      requiredErrorMsg: 'Surname is required.'
    },
    FullName: {
      information_text: 'Please ensure your first name and surname fields above match your bank account name.'
    },
    Fund: {
      name: 'Fund',
      label: 'Fund*',
      requiredErrorMsg: 'Fund is required.'
    },
    BIC: {
      name: 'BIC',
      label: 'BIC',
      incorrectBicErrorMsg: 'Please enter valid BIC.'
    },
    IBAN: {
      name: 'IBAN',
      label: 'IBAN*',
      requiredErrorMsg: 'IBAN is required.',
      incorrectIbanErrorMsg: 'Please enter valid IBAN.'
    },
    TypeOfDirectDebit: {
      name: 'TypeOfDirectDebit',
      label: 'Type of direct debit*',
      requiredErrorMsg: 'Type of direct debit is required.'
    },
    PaymentFrequency: {
      name: 'PaymentFrequency',
      label: 'Payment frequency*',
      requiredErrorMsg: 'Payment frequency is required.'
    },
    Amount: {
      name: 'Amount',
      label: 'Amount*',
      requiredErrorMsg: 'Amount is required.',
      typeErrorMsg: 'Amount must be a positive number.',
      minErrorMsg: 'Amount must be a positive number.'
    },
    SupressPaymentIntentCreation: {
      name: 'SupressPaymentIntentCreation',
      label: 'Supress payment intent creation*',
      requiredErrorMsg: 'Supress payment intent creation is required.'
    },
  }
};

export default DirectDebitFormModel;
