import LoadingComponent from "../../app/components/LoadingComponent";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { Box, Paper, Grid, Button, Typography } from "@mui/material";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { useHistory, useParams } from "react-router-dom";
import { useGetDirectDebitPaymentIntents } from "../../app/customHooks/DirectDebitPaymentIntent/useGetDirectDebitPaymentIntents";
import { useEffect, useState } from "react";
import { DirectDebitCreditor } from "../../app/models/referenceData/directDebitCreditor";
import DirectDebitRunFormModel from "./common/directDebitRunFormModel";
import { useGetReferenceData } from "../../app/customHooks/ReferenceData/useGetReferenceData";
import CreditorPaymentIntents from "./CreditorPaymentIntents";
import { useForm } from "react-hook-form";
import AppDatePicker from "../../app/components/AppDatePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import { createDDRunValidationSchemas } from "./common/createDDRunValidationSchemas";
import { CreateDirectDebitRunCommand } from "../../app/models/directDebitRun/createDirectDebitRunCommand";
import { toast } from "react-toastify";
import { submitDirectDebitRunAsync } from "../../app/slices/directDebitRunSlice";

export default function CreateDirectDebitRun() {
    const {
        formField: {
            CreditorName,
            NumberOfPaymentIntents,
            CollectionDueDate
        }
    } = DirectDebitRunFormModel;

    const history = useHistory();
    const { creditorId } = useParams<{ creditorId: string }>();

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(createDDRunValidationSchemas)
    });

    const { control, trigger, getValues, formState: { isDirty } } = methods;

    useGetReferenceData();
    useGetDirectDebitPaymentIntents();

    const { creditors, referenceDataStatus } = useAppSelector(state => state.referenceData);
    const { directDebitPaymentIntents, directDebitPaymentIntentStatus } = useAppSelector(state => state.directDebitPaymentIntent);
    const { submitDirectDebitRunStatus } = useAppSelector(state => state.directDebitRun);

    const [creditor, setCreditor] = useState<DirectDebitCreditor>();

    useEffect(() => {
        if (creditors && creditors?.length > 0) {
            setCreditor(creditors.find(x => x.id === creditorId));
        }
    }, [creditors, creditorId]);

    const dispatch = useAppDispatch();

    const submitCreditorInfo = async () => {
        const isStepValid = await trigger();
        if (isStepValid) {
            if (isDirty === true) {
                let createDirectDebitRunCommand: CreateDirectDebitRunCommand = {
                    creditorId: creditorId,
                    collectionDate: getValues(CollectionDueDate.name)
                };

                dispatch(submitDirectDebitRunAsync(createDirectDebitRunCommand)).then((response: any) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        if (response.payload === "") {
                            toast.error("The direct debit run couldn't be created.");
                        } else {
                            toast.success("Direct debit run created successfully.");
                        }
                        backToDDRuns();
                    } else {
                        toast.error("Error while creating direct debit run");
                    }
                });
            }
        }
    }

    const backToDDRuns = () => {
        history.push(`/direct-debits-run`);
    }

    if (directDebitPaymentIntentStatus === ApiRequestStatus.Pending ||
        submitDirectDebitRunStatus === ApiRequestStatus.Pending ||
        referenceDataStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box component={Paper} sx={{ padding: 3 }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}>
                    <Typography variant='h3' sx={{ textAlign: 'center' }}>Create direct debit run</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>Creditor info</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={3}>
                    <Typography variant='body1'><b>{CreditorName.label}</b></Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant='body2'>{creditor?.value}</Typography>
                </Grid>

                <Grid item xs={5}></Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={3}>
                    <Typography variant='body1'><b>{NumberOfPaymentIntents.label}</b></Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant='body2'>
                        {directDebitPaymentIntents?.filter(x => x.mandate?.directDebitCreditorId === creditorId).length}
                    </Typography>
                </Grid>

                <Grid item xs={5}></Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={3}>
                    <Typography variant='body1'><b>{CollectionDueDate.labelRequired}</b></Typography>
                </Grid>

                <Grid item xs={3}>
                    <AppDatePicker control={control}
                        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                        name={CollectionDueDate.name} label={CollectionDueDate.labelRequired} />
                </Grid>

                <Grid item xs={5}></Grid>

                <Grid item xs={1}></Grid>

            </Grid>
        </Box>

        <Box component={Paper} sx={{ padding: 3 }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={1}></Grid>

                <Grid item xs={2}>
                    <Button variant="outlined" onClick={backToDDRuns}>
                        Cancel
                    </Button>
                </Grid>

                <Grid item xs={2}>
                    <Button variant="contained" onClick={submitCreditorInfo}>
                        Submit
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>

            </Grid>
        </Box>

        <CreditorPaymentIntents />
    </>
}
