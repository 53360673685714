import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { DirectDebitPaymentIntent } from "../models/directDebitRun/directDebitPaymentIntent";
import { CreateDonationsIntentsCommand } from "../models/directDebitRun/createDonationsIntentsCommand";

interface directDebitState {
    directDebitPaymentIntents: DirectDebitPaymentIntent[] | null;
    directDebitPaymentIntentStatus: ApiRequestStatus;
    createDonationIntentStatus: ApiRequestStatus;
    createFamIntentStatus: ApiRequestStatus;
}

const initialState: directDebitState = {
    directDebitPaymentIntents: null,
    directDebitPaymentIntentStatus: ApiRequestStatus.Idle,
    createDonationIntentStatus: ApiRequestStatus.Idle,
    createFamIntentStatus: ApiRequestStatus.Idle
}

export const getDirectDebitPaymentIntentsAsync = createAsyncThunk<DirectDebitPaymentIntent[]>(
    'directDebitPaymentIntentSlice/getDirectDebitPaymentIntentsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.directDebitPaymentIntent.getDirectDebitPaymentIntentsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const submitDonationPaymentIntentAsync = createAsyncThunk<DirectDebitPaymentIntent[], CreateDonationsIntentsCommand>(
    'directDebitPaymentIntentSlice/submitDonationPaymentIntentAsync',
    async (createDonationsIntentsCommand, thunkAPI) => {
        try {
            return agent.directDebitPaymentIntent.submitDonationPaymentIntentAsync(createDonationsIntentsCommand);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const submitFamPaymentIntentAsync = createAsyncThunk<DirectDebitPaymentIntent[]>(
    'directDebitPaymentIntentSlice/submitFamPaymentIntentAsync',
    async (_, thunkAPI) => {
        try {
            return agent.directDebitPaymentIntent.submitFamPaymentIntentAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const directDebitPaymentIntentSlice = createSlice({
    name: 'directDebitPaymentIntentSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getDirectDebitPaymentIntentsAsync.pending, (state) => {
            state.directDebitPaymentIntentStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getDirectDebitPaymentIntentsAsync.fulfilled, (state, action) => {
            state.directDebitPaymentIntents = action.payload;
            state.directDebitPaymentIntentStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getDirectDebitPaymentIntentsAsync.rejected, (state) => {
            state.directDebitPaymentIntentStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(submitDonationPaymentIntentAsync.pending, (state) => {
            state.createDonationIntentStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitDonationPaymentIntentAsync.fulfilled, (state, action) => {
            state.createDonationIntentStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitDonationPaymentIntentAsync.rejected, (state) => {
            state.createDonationIntentStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(submitFamPaymentIntentAsync.pending, (state) => {
            state.createFamIntentStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitFamPaymentIntentAsync.fulfilled, (state, action) => {
            state.createFamIntentStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitFamPaymentIntentAsync.rejected, (state) => {
            state.createFamIntentStatus = ApiRequestStatus.Rejected;
        });
    })
})
