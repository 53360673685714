import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { ApiRequestStatus } from '../../enums/apiRequestStatus';
import { referenceDataAsync } from '../../slices/referenceDataSlice';

export const useGetReferenceData = () => {
    const dispatch = useAppDispatch();
    const { documentTypes, productTypes, paymentMethods, funds, currencies, creditors, referenceDataStatus } = useAppSelector(state => state.referenceData);

    useEffect(() => {
        if ((documentTypes === null || documentTypes?.length === 0) &&
            (productTypes === null || productTypes?.length === 0) &&
            (paymentMethods === null || paymentMethods?.length === 0) &&
            (funds === null || funds?.length === 0) &&
            (currencies === null || currencies?.length === 0) &&
            (creditors === null || creditors?.length === 0) &&
            referenceDataStatus === ApiRequestStatus.Idle) {
            dispatch(referenceDataAsync());
        }
    }, [documentTypes, productTypes, paymentMethods, funds, currencies, creditors, referenceDataStatus, dispatch]);

    return { referenceDataStatus };
};
