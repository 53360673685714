import * as yup from 'yup';
import DirectDebitRunFormModel from './directDebitRunFormModel';

const {
  formField: {
    CollectionDueDate
  }
} = DirectDebitRunFormModel;

export const createDDRunValidationSchemas = yup.object().shape({
  [CollectionDueDate.name]: yup.string().required(`${CollectionDueDate.requiredErrorMsg}`)
});
