import LoadingComponent from "../../app/components/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { useAppSelector } from "../../app/store/configureStore";
import { DirectDebitCreditor } from "../../app/models/referenceData/directDebitCreditor";
import { useGetReferenceData } from "../../app/customHooks/ReferenceData/useGetReferenceData";
import { useGetDirectDebitPaymentIntents } from "../../app/customHooks/DirectDebitPaymentIntent/useGetDirectDebitPaymentIntents";
import { useHistory } from "react-router-dom";

export default function CreditorList() {

    const history = useHistory();

    useGetReferenceData();
    useGetDirectDebitPaymentIntents();

    const { creditors, referenceDataStatus } = useAppSelector(state => state.referenceData);
    const { directDebitPaymentIntents, directDebitPaymentIntentStatus } = useAppSelector(state => state.directDebitPaymentIntent);

    if (directDebitPaymentIntentStatus === ApiRequestStatus.Pending ||
        referenceDataStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <Box component={Paper} sx={{ padding: 3 }}>

        <Grid container rowSpacing={4}>

            <Grid item xs={12}>
                <Typography variant='h4'>Creditors</Typography>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid container sx={{
                backgroundColor: '#616161',
                color: "#fff",
                padding: 2,
                textAlign: 'left'
            }}>
                <Grid item xs={0.5}></Grid>

                <Grid item xs={5}>
                    Name
                </Grid>

                <Grid item xs={2}>
                    Waiting payment intents
                </Grid>

                <Grid item xs={2}></Grid>

                <Grid item xs={1}></Grid>
            </Grid>

            {(creditors === undefined || creditors == null || creditors?.length === 0) &&
                <Grid container sx={{
                    textAlign: 'left',
                    alignItems: 'center'
                }}>

                    <Grid item xs={12}></Grid>
                    <br />

                    <Grid item xs={12} sx={{
                        textAlign: 'center'
                    }}>
                        No creditors found.
                    </Grid>

                    <Grid item xs={12}></Grid>

                </Grid>
            }

            {creditors?.map((item: DirectDebitCreditor, index: number) => (
                <Grid container key={index} sx={{
                    textAlign: 'left',
                    alignItems: 'center'
                }} rowSpacing={0.5}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={0.5}></Grid>

                    <Grid item xs={4}>
                        {item.value}
                    </Grid>

                    <Grid item xs={2} sx={{ textAlign: 'center' }}>
                        {directDebitPaymentIntents?.filter(x => x.mandate?.directDebitCreditorId === item.id).length}
                    </Grid>

                    <Grid item xs={2}></Grid>

                    <Grid item xs={3.5}>
                        <Button variant="outlined" onClick={() => history.push(`/create-dd-run/${item.id}`)}
                            disabled={directDebitPaymentIntents?.filter(x => x.mandate?.directDebitCreditorId === item.id).length === 0}>
                            Create direct debit run
                        </Button>
                    </Grid>

                </Grid>
            ))}

            <Grid item xs={12}></Grid>

        </Grid>
    </Box>
}
