import LoadingComponent from "../../app/components/LoadingComponent";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { Box, Paper, Grid, Button } from "@mui/material";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { useHistory, useParams } from "react-router-dom";
import DirectDebitPaymentIntents from "./DirectDebitPaymentIntents";
import { toast } from "react-toastify";
import { deleteDirectDebitRunByIdAsync, getDirectDebitRunFileAsync } from "../../app/slices/directDebitRunSlice";
import GetDirectDebitRunDetails from "./GetDirectDebitRunDetails";
import { useGetDirectDebitPaymentIntents } from "../../app/customHooks/DirectDebitPaymentIntent/useGetDirectDebitPaymentIntents";

export default function DirectDebitRun() {

    const history = useHistory();
    const { directDebitRunId } = useParams<{ directDebitRunId: string }>();
    useGetDirectDebitPaymentIntents();

    const { directDebitsRun, directDebitsRunStatus, deleteDirectDebitRunStatus,
        directDebitRunFileStatus } = useAppSelector(state => state.directDebitRun);

    const dispatch = useAppDispatch();

    const abandonDDRun = async () => {
        dispatch(deleteDirectDebitRunByIdAsync(directDebitRunId)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success("Direct debit run abandoned successfully.");
                backToDDRuns();
            }
            else {
                toast.error("Error while abandoning direct debit run.");
            }
        });
    }

    const generateFile = async () => {
        dispatch(getDirectDebitRunFileAsync(directDebitRunId)).then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response.payload], { type: 'application/xml' }));
            const link = document.createElement('a');

            link.href = url;
            link.download = "DirectDebitRun.xml";

            document.body.appendChild(link);

            link.click();
            link.remove();

            window.URL.revokeObjectURL(url);
        });
    }

    const backToDDRuns = () => {
        history.push(`/direct-debits-run`);
    }

    if (directDebitsRunStatus === ApiRequestStatus.Pending ||
        deleteDirectDebitRunStatus === ApiRequestStatus.Pending ||
        directDebitRunFileStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <GetDirectDebitRunDetails />

        <Box component={Paper} sx={{ padding: 3 }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={1}></Grid>

                <Grid item xs={1.5}>
                    <Button variant="contained" onClick={abandonDDRun}>
                        Abandon
                    </Button>
                </Grid>

                <Grid item xs={1.5}>
                    <Button variant="contained" onClick={() => {
                        history.push(`/reconcile-dd-run/${directDebitRunId}`);
                    }} disabled={directDebitsRun?.find(x => x.id === directDebitRunId)?.paymentIntents?.length === 0}>
                        Reconcile
                    </Button>
                </Grid>

                <Grid item xs={2}>
                    <Button variant="contained" onClick={generateFile}
                        disabled={directDebitsRun?.find(x => x.id === directDebitRunId)?.paymentIntents?.length === 0}>
                        Generate file
                    </Button>
                </Grid>

                <Grid item xs={2}>
                    <Button variant="outlined" onClick={backToDDRuns}>
                        Back to DD runs
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>

            </Grid>
        </Box>

        <DirectDebitPaymentIntents />
    </>
}
