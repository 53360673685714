import LoadingComponent from "../../app/components/LoadingComponent";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { DirectDebitCreditor } from "../../app/models/referenceData/directDebitCreditor";
import { useGetReferenceData } from "../../app/customHooks/ReferenceData/useGetReferenceData";
import { useHistory } from "react-router-dom";
import AppDatePicker from "../../app/components/AppDatePicker";
import DirectDebitRunFormModel from "./common/directDebitRunFormModel";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createDDRunValidationSchemas } from "./common/createDDRunValidationSchemas";
import { CreditorEnum } from "../../app/enums/creditorEnum";
import { submitDonationPaymentIntentAsync } from "../../app/slices/directDebitPaymentIntentSlice";
import { CreateDonationsIntentsCommand } from "../../app/models/directDebitRun/createDonationsIntentsCommand";
import { toast } from "react-toastify";

export default function GenerateDonationsPaymentIntent() {
    const {
        formField: {
            CreditorName,
            CollectionDueDate
        }
    } = DirectDebitRunFormModel;

    const history = useHistory();

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(createDDRunValidationSchemas)
    });

    const { control, trigger, getValues, formState: { isDirty } } = methods;

    useGetReferenceData();

    const { creditors, referenceDataStatus } = useAppSelector(state => state.referenceData);
    const { createDonationIntentStatus } = useAppSelector(state => state.directDebitPaymentIntent);

    const [creditor, setCreditor] = useState<DirectDebitCreditor>();

    useEffect(() => {
        if (creditors && creditors?.length > 0) {
            setCreditor(creditors.find(x => x.code === CreditorEnum.DevelopmentAndAlumniRelationsOffice));
        }
    }, [creditors]);

    const backToDDRuns = () => {
        history.push(`/direct-debits-run`);
    }

    const dispatch = useAppDispatch();

    const submitDonationsPaymentIntent = async () => {
        const isStepValid = await trigger();
        if (isStepValid) {
            if (isDirty === true) {
                const data: CreateDonationsIntentsCommand = {
                    collectionDate: getValues(CollectionDueDate.name)
                };
                dispatch(submitDonationPaymentIntentAsync(data)).then((response: any) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        toast.success("Donations payment intent generated successfully.");
                        backToDDRuns();
                    } else {
                        toast.error("Error while generating donations payment intent");
                    }
                });
            }
        }
    }

    if (referenceDataStatus === ApiRequestStatus.Pending ||
        createDonationIntentStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <Box component={Paper} sx={{ padding: 3 }}>
        <Grid container rowSpacing={4}>

            <Grid item xs={12}>
                <Typography variant='h4'>Generate donations payment intents</Typography>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={3}>
                <Typography variant='body1'><b>{CreditorName.label}</b></Typography>
            </Grid>

            <Grid item xs={3}>
                <Typography variant='body2'>{creditor?.value}</Typography>
            </Grid>

            <Grid item xs={5}></Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={3}>
                <Typography variant='body1'><b>{CollectionDueDate.labelRequired}</b></Typography>
            </Grid>

            <Grid item xs={3}>
                <AppDatePicker control={control}
                    minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                    name={CollectionDueDate.name} label={CollectionDueDate.labelRequired} />
            </Grid>

            <Grid item xs={5}></Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={3}></Grid>

            <Grid item xs={1.5}>
                <Button variant="outlined" onClick={backToDDRuns}>
                    Cancel
                </Button>
            </Grid>

            <Grid item xs={2}>
                <Button variant="contained" onClick={submitDonationsPaymentIntent}>
                    Submit
                </Button>
            </Grid>

        </Grid>
    </Box>
}
