import { Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, ThemeProvider, createTheme } from '@mui/material';
import FileList from '../../features/Document/FileList';
import { AuthenticatedTemplate, MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';
import RequestInterceptor from '../api/RequestInterceptor';
import { useEffect } from 'react';
import { isProductionMode } from '../utils/util';
import DirectDebitRunForm from '../../features/DirectDebitRun/DirectDebitRunForm';
import DirectDebitRun from '../../features/DirectDebitRun/DirectDebitRun';
import ReconcileDirectDebitRun from '../../features/DirectDebitRun/ReconcileDirectDebitRun';
import CreateDirectDebitRun from '../../features/DirectDebitRun/CreateDirectDebitRun';
import GenerateDonationsPaymentIntent from '../../features/DirectDebitRun/GenerateDonationsPaymentIntent';
import { Payments } from '../../features/Payments/Payments';
import GenerateMembershipPaymentIntent from '../../features/DirectDebitRun/GenerateMembershipPaymentIntent';

function App({ msalInstance }: any) {

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <Pages />
      </MsalProvider>
    </>
  );
}

function Pages() {

  const paletteType = 'light';

  const theme = createTheme({
    palette: {
      mode: paletteType,
      primary: {
        light: "#F08080",
        main: "#DE1834",
        dark: "#DC143C",
        contrastText: "#fff"
      },
      background: {
        default: '#f3f3f3'
      }
    },
    components: {
      // GLOBAL CHANGES TO DEFAULT/BASE THEME
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application!
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            boxShadow: "none"
            , '&:hover': {
              boxShadow: "none"
            },
            fontSize: '1rem',
          }
        }
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            marginTop: '0px !important',
            marginBottom: '0px !important',
          },
          outlined: {
            border: '0',
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            borderRadius: 0,
            boxShadow: "none"
            , '&:hover': {
              boxShadow: "none"
            },
            fontSize: '1rem',
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {

          },
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#222',
          }
        },
      },
      MuiStep: {
        styleOverrides: {
          root: {
            paddingLeft: '4px',
          }
        },
      }
    },
  })

  useEffect(() => {
    if (!isProductionMode()) {
      const metaRobots = document.createElement('meta');
      metaRobots.name = 'robots';
      metaRobots.content = 'noindex,nofollow';
      document.head.appendChild(metaRobots);
    }
  }, []);

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleRedirect = () => {
    instance.loginRedirect({
      ...loginRequest,
      prompt: 'create',
    });
  };

  if (isAuthenticated === false) {
    handleRedirect()
  }

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <RequestInterceptor>
          <ThemeProvider theme={theme}>
            <ToastContainer position='top-center' hideProgressBar theme='colored' />
            <Container>
              <Switch>
                <Route path="/" exact component={FileList} />
                <Route path="/files" component={FileList} />
                <Route path="/direct-debits-run" component={DirectDebitRunForm} />
                <Route path="/create-dd-run/:creditorId" component={CreateDirectDebitRun} />
                <Route path="/direct-debit-run/:directDebitRunId" component={DirectDebitRun} />
                <Route path="/reconcile-dd-run/:directDebitRunId" component={ReconcileDirectDebitRun} />
                <Route path="/donations-payment-intent" component={GenerateDonationsPaymentIntent} />
                <Route path="/membership-payment-intent" component={GenerateMembershipPaymentIntent} />
                <Route path="/payments" component={Payments} />
              </Switch>
            </Container>
          </ThemeProvider>
        </RequestInterceptor>
      </AuthenticatedTemplate>
    </div>
  );
}

export default App;
