import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoadingComponent from "../../app/components/LoadingComponent";
import { useGetReferenceData } from "../../app/customHooks/ReferenceData/useGetReferenceData";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { DirectDebitCreditor } from "../../app/models/referenceData/directDebitCreditor";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import DirectDebitRunFormModel from "./common/directDebitRunFormModel";
import { CreditorEnum } from "../../app/enums/creditorEnum";
import { toast } from "react-toastify";
import { submitFamPaymentIntentAsync } from "../../app/slices/directDebitPaymentIntentSlice";

export default function GenerateMembershipPaymentIntent() {
    const {
        formField: {
            CreditorName
        }
    } = DirectDebitRunFormModel;

    const history = useHistory();
    
    useGetReferenceData();

    const { creditors, referenceDataStatus } = useAppSelector(state => state.referenceData);
    const { createFamIntentStatus } = useAppSelector(state => state.directDebitPaymentIntent);

    const [creditor, setCreditor] = useState<DirectDebitCreditor>();

    useEffect(() => {
        if (creditors && creditors?.length > 0) {
            setCreditor(creditors.find(x => x.code === CreditorEnum.FellowsAndMembersOffice));
        }
    }, [creditors]);

    const backToDDRuns = () => {
        history.push(`/direct-debits-run`);
    }

    const dispatch = useAppDispatch();

    const submitFamPaymentIntent = async () => {
        dispatch(submitFamPaymentIntentAsync()).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success("Fam payment intent generated successfully.");
                backToDDRuns();
            } else {
                toast.error("Error while generating fam payment intent");
            }
        });
    }

    if (referenceDataStatus === ApiRequestStatus.Pending ||
        createFamIntentStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <Box component={Paper} sx={{ padding: 3 }}>
        <Grid container rowSpacing={4}>

            <Grid item xs={12}>
                <Typography variant='h4'>Generate membership payment intents</Typography>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={3}>
                <Typography variant='body1'><b>{CreditorName.label}</b></Typography>
            </Grid>

            <Grid item xs={3}>
                <Typography variant='body2'>{creditor?.value}</Typography>
            </Grid>

            <Grid item xs={5}></Grid>

            <Grid item xs={4}></Grid>

            <Grid item xs={1.5}>
                <Button variant="outlined" onClick={backToDDRuns}>
                    Cancel
                </Button>
            </Grid>

            <Grid item xs={2}>
                <Button variant="contained" onClick={submitFamPaymentIntent}>
                    Submit
                </Button>
            </Grid>

        </Grid>
    </Box>
}
