import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { ApiRequestStatus } from '../../enums/apiRequestStatus';
import { getDirectDebitsRunAsync } from '../../slices/directDebitRunSlice';

export const useGetDirectDebitsRun = () => {
    const dispatch = useAppDispatch();
    const { directDebitsRun, directDebitsRunStatus } = useAppSelector(state => state.directDebitRun);

    useEffect(() => {
        if (directDebitsRun === null && directDebitsRunStatus === ApiRequestStatus.Idle) {
            dispatch(getDirectDebitsRunAsync());
        }
    }, [directDebitsRun, directDebitsRunStatus, dispatch]);

    return { directDebitsRunStatus };
};
