import { Box, Paper, Grid, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function PaymentIntentActions() {

    const history = useHistory();

    return <Box component={Paper} sx={{ padding: 3 }}>
        <Grid container rowSpacing={4}>

            <Grid item xs={1}></Grid>

            <Grid item xs={4}>
                <Button variant="contained" onClick={() => history.push('/donations-payment-intent')}>
                    Generate donations payment intents
                </Button>
            </Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={5}>
                <Button variant="contained" onClick={() => history.push('/membership-payment-intent')}>
                    Generate Membership payment intents
                </Button>
            </Grid>

            <Grid item xs={1}></Grid>

        </Grid>
    </Box>
}
