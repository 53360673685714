import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import AppSelectList from "../../app/components/AppSelectList";
import { DropdownItem } from "../../app/models/common/dropdownItem";
import { ProductModel } from "./common/productModel";
import { PaymentTypes } from "../../app/enums/paymentTypes";
import PaymentItems from "./PaymentItems";
import { useFormContext } from "react-hook-form";
import ManualPaymentFormModel from "./common/manualPaymentFormModel";
import { useEffect, useState } from "react";
import ProductList from "./ProductList";
import { Currency } from "../../app/models/referenceData/currency";
import { FundDto } from "../../app/models/manualPayment/fundDto";

interface Props {
    filteredFunds: DropdownItem[];
    funds: FundDto[];
    productTypes: DropdownItem[];
    years: DropdownItem[];
    currencies: Currency[];
    productRows: ProductModel[];
    addProductClicked?: (productItem: ProductModel) => void;
    deleteProductClicked?: (item: string) => void;
}

export default function ProductDetails({ filteredFunds, funds, productTypes, years, currencies, productRows, addProductClicked, deleteProductClicked }: Props) {
    const {
        formField: {
            MembershipYear,
            Fund,
            Amount,
            PaymentType,
            Currency }
    } = ManualPaymentFormModel;

    const methods = useFormContext();
    const { control, getValues, setValue, watch } = methods;
    const [canShowPaymentItems, setCanShowPaymentItems] = useState<boolean>(false);
    const [canShowAddProductButton, setCanShowAddProductButton] = useState<boolean>(false);

    const addProductButtonAccessibility = () => {
        if ((years.length === 0 && getValues(PaymentType.name) === PaymentTypes.FAM_ANUAL_MEMBERSHIP)
            || (filteredFunds !== null && filteredFunds.length === 0 && getValues(PaymentType.name) === PaymentTypes.DONATION)) {
            setCanShowAddProductButton(false);
        }
        else {
            setCanShowAddProductButton(true);
        }
    }

    useEffect(() => {
        addProductButtonAccessibility();
    }, [years, filteredFunds]);

    useEffect(() => {
        watch((value, { name, type }) => {
            if (funds && funds?.length > 0) {
                if (name === PaymentType.name && type === 'change') {
                    setCanShowPaymentItems(false);
                    addProductButtonAccessibility();
                    setValue(MembershipYear.name, '');
                    setValue(Fund.name, '');
                    setValue(Amount.name, '');
                }
            }
        });
    }, [funds, PaymentType.name, Currency.name, watch, productTypes, getValues]);

    const [paymentTypeError, setPaymentTypeError] = useState<string>();

    const newProductClicked = () => {
        let paymentType = getValues(PaymentType.name);
        let hasPaymentTypeError = false;

        if (paymentType === '' || paymentType === undefined) {
            setPaymentTypeError(PaymentType.requiredErrorMsg);
            hasPaymentTypeError = true;
        }
        else {
            setPaymentTypeError('');
        }

        if (hasPaymentTypeError === true) return;

        setCanShowPaymentItems(true);
    }

    return (
        <>
            <Box sx={{ minWidth: "100%" }} component={Paper}>
                <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                    <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                        <Typography variant='h6' sx={{ fontWeight: "500", textAlign: 'left' }}>Payment products</Typography>
                    </Grid>

                    <Grid item xs={9}>
                        <AppSelectList control={control} name={PaymentType.name} label={PaymentType.label}
                            items={productTypes} />
                    </Grid>

                    <Grid item xs={3} sx={{ marginTop: '6px' }}>
                        <Button variant="contained" color="primary" onClick={newProductClicked}
                            disabled={!canShowAddProductButton}>
                            Add product
                        </Button>
                    </Grid>

                    <Grid xs={12}>
                        <p className="Mui-error css-1wc848c-MuiFormHelperText-root">{paymentTypeError}</p>
                    </Grid>

                    <Grid item xs={12}></Grid>

                    {canShowPaymentItems && <PaymentItems funds={filteredFunds} years={years}
                        addProductClicked={(productItem: ProductModel) => {
                            setCanShowPaymentItems(false);
                            addProductClicked && addProductClicked(productItem);
                        }} />}

                </Grid>
            </Box>

            <Box sx={{ flexGrow: 1, minWidth: "500px" }} component={Paper}>
                <Grid container rowSpacing={4} sx={{ margin: "2%" }}>
                    <ProductList funds={funds ?? []} productRows={productRows} currencies={currencies}
                        productTypes={productTypes} deleteProductClicked={(productId) => {
                            deleteProductClicked && deleteProductClicked(productId);
                        }} />
                </Grid>
            </Box >
        </>
    )
}
