import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { ReconcileDDRunValidationSchemas } from "./common/reconcileDDRunValidationSchemas";
import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import AppDatePicker from "../../app/components/AppDatePicker";
import { formatDateWithTime } from "../../app/utils/util";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoadingComponent from "../../app/components/LoadingComponent";
import { useGetDirectDebitsRun } from "../../app/customHooks/DirectDebitRun/useGetDirectDebitsRun";
import { useGetReferenceData } from "../../app/customHooks/ReferenceData/useGetReferenceData";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { DirectDebitRunDto } from "../../app/models/directDebitRun/directDebitRunDto";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { DirectDebitPaymentIntent } from "../../app/models/directDebitRun/directDebitPaymentIntent";
import { ReconcileDirectDebitRunCommand } from "../../app/models/directDebitRun/reconcileDirectDebitRunCommand";
import { toast } from "react-toastify";
import { DirectDebitPaymentDto } from "../../app/models/directDebitRun/directDebitPaymentDto";
import { reconcileDirectDebitRunAsync } from "../../app/slices/directDebitRunSlice";
import DirectDebitRunFormModel from "./common/directDebitRunFormModel";
import { useGetDirectDebitPaymentIntents } from "../../app/customHooks/DirectDebitPaymentIntent/useGetDirectDebitPaymentIntents";

export default function ReconcileDirectDebitRun() {
    const {
        formField: {
            Reference,
            CreditorName,
            CreatedDate,
            CreatedBy,
            ReturnDate,
            Failed,
            Regenerate
        }
    } = DirectDebitRunFormModel;

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(ReconcileDDRunValidationSchemas)
    });

    const history = useHistory();
    const { trigger, getValues, control, formState: { isDirty } } = methods;
    const { directDebitRunId } = useParams<{ directDebitRunId: string }>();

    useGetReferenceData();
    useGetDirectDebitsRun();

    const { creditors, referenceDataStatus } = useAppSelector(state => state.referenceData);
    const { directDebitsRun, directDebitsRunStatus, reconcileDirectDebitRunStatus } = useAppSelector(state => state.directDebitRun);

    const [directDebitRun, setDirectDebitRun] = useState<DirectDebitRunDto>();

    const [directDebitPaymentIntents, setDirectDebitPaymentIntents] = useState<DirectDebitPaymentIntent[]>([]);

    useEffect(() => {
        const paymentIntentList = directDebitsRun?.find(x => x.id === directDebitRunId)?.paymentIntents;
        if (paymentIntentList && paymentIntentList?.length > 0) {
            setDirectDebitPaymentIntents(paymentIntentList);
        }
    }, [directDebitsRun, directDebitRunId]);

    useEffect(() => {
        if (directDebitRunId !== '' && directDebitsRun && directDebitsRun?.length > 0) {
            setDirectDebitRun(directDebitsRun.find(x => x.id === directDebitRunId));
        }
    }, [directDebitsRun]);

    const [paymentIntents, setPaymentIntents] = useState<DirectDebitPaymentDto[]>();

    useEffect(() => {
        const ddRunPaymentIntents = directDebitPaymentIntents?.filter(x => x.directDebitRunId === directDebitRunId)
        if (ddRunPaymentIntents && ddRunPaymentIntents.length > 0) {
            let filteredPaymentIntents: DirectDebitPaymentDto[] = [];
            ddRunPaymentIntents.map((item) => {
                const directDebitPaymentDtoObj: DirectDebitPaymentDto = {
                    paymentIntentId: item.id, paid: true, regenerate: false
                };
                filteredPaymentIntents.push(directDebitPaymentDtoObj);
            });
            setPaymentIntents(filteredPaymentIntents);
        }
    }, [directDebitPaymentIntents]);

    const dispatch = useAppDispatch();

    const submitPaymentIntents = async () => {
        const isStepValid = await trigger();
        if (isStepValid) {
            if (isDirty === true) {
                if (paymentIntents) {
                    let reconcileDirectDebitRunCommand: ReconcileDirectDebitRunCommand = {
                        directDebitRunId: directDebitRunId,
                        collectionDate: getValues(ReturnDate.name),
                        payments: paymentIntents,
                        reference: directDebitRun?.reference
                    };

                    dispatch(reconcileDirectDebitRunAsync(reconcileDirectDebitRunCommand)).then((response: any) => {
                        if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                            toast.success("Direct debit reconciled successfully.");
                            history.push(`/direct-debits-run`);
                        } else {
                            toast.error("Error while reconciling direct debit.");
                        }
                    });
                }
            }
        }
    }

    const onPaymentIntentsChange = (e: React.ChangeEvent<HTMLInputElement>, paymentIntentId: string) => {
        const updatedPaymentIntents = paymentIntents?.map((paymentIntentItem: DirectDebitPaymentDto) => {
            if (paymentIntentItem.paymentIntentId === paymentIntentId) {
                return { ...paymentIntentItem, paid: !e.target.checked };
            }
            return paymentIntentItem;
        });
        setPaymentIntents(updatedPaymentIntents);
    }

    const onRegenerateChange = (e: React.ChangeEvent<HTMLInputElement>, paymentIntentId: string) => {
        const updatedPaymentIntents = paymentIntents?.map((paymentIntentItem: DirectDebitPaymentDto) => {
            if (paymentIntentItem.paymentIntentId === paymentIntentId) {
                return { ...paymentIntentItem, regenerate: e.target.checked };
            }
            return paymentIntentItem;
        });
        setPaymentIntents(updatedPaymentIntents);
    }

    if (directDebitsRunStatus === ApiRequestStatus.Pending ||
        referenceDataStatus === ApiRequestStatus.Pending ||
        reconcileDirectDebitRunStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <FormProvider {...methods}>
        <Box component={Paper} sx={{ padding: 3 }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}>
                    <Typography variant='h4'>Active direct debit run</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={3}>
                    <Typography variant='body1'><b>{Reference.label}</b></Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant='body2'>{directDebitRun?.reference}</Typography>
                </Grid>

                <Grid item xs={5}></Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={3}>
                    <Typography variant='body1'><b>{CreditorName.label}</b></Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant='body2'>{creditors?.find(x => x.id === directDebitRun?.directDebitCreditorId)?.value}</Typography>
                </Grid>

                <Grid item xs={5}></Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={3}>
                    <Typography variant='body1'><b>{CreatedDate.label}</b></Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant='body2'>{directDebitRun?.createdDate && formatDateWithTime(new Date(directDebitRun?.createdDate))}</Typography>
                </Grid>

                <Grid item xs={5}></Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={3}>
                    <Typography variant='body1'><b>{CreatedBy.label}</b></Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant='body2'>{directDebitRun?.createdby}</Typography>
                </Grid>

                <Grid item xs={5}></Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={3}>
                    <Typography variant='body1'><b>{ReturnDate.label}</b></Typography>
                </Grid>

                <Grid item xs={3}>
                    <AppDatePicker control={control} name={ReturnDate.name} label={ReturnDate.label}
                        maxDate={new Date(new Date().setDate(new Date().getDate()))} />
                </Grid>

                <Grid item xs={5}></Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box component={Paper} sx={{ padding: 3 }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}>
                    <Typography variant='h4'>Payment intents</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid container sx={{
                    backgroundColor: '#616161',
                    color: "#fff",
                    padding: 2,
                    textAlign: 'left'
                }}>
                    <Grid item xs={0.5}></Grid>

                    <Grid item xs={3.5}>
                        Reference
                    </Grid>

                    <Grid item xs={2}>
                        Amount
                    </Grid>

                    <Grid item xs={3}>
                        Name
                    </Grid>

                    <Grid item xs={0.5}>{Failed.label}</Grid>

                    <Grid item xs={0.5}></Grid>

                    <Grid item xs={0.5}>{Regenerate.label}</Grid>

                    <Grid item xs={1.5}></Grid>
                </Grid>

                {(directDebitPaymentIntents === undefined || directDebitPaymentIntents == null ||
                    directDebitPaymentIntents?.filter(x => x.directDebitRunId === directDebitRunId)?.length === 0) &&
                    <Grid container sx={{
                        textAlign: 'left',
                        alignItems: 'center'
                    }} rowSpacing={2}>

                        <Grid item xs={12}></Grid>
                        <br />

                        <Grid item xs={12} sx={{
                            textAlign: 'center'
                        }}>
                            No payment intents found.
                        </Grid>

                        <Grid item xs={12}></Grid>

                    </Grid>
                }

                {directDebitPaymentIntents?.filter(x => x.directDebitRunId === directDebitRunId).map((item: DirectDebitPaymentIntent, index: number) => (
                    <Grid container key={item.id} sx={{
                        textAlign: 'left',
                        alignItems: 'center'
                    }} rowSpacing={2}>

                        <Grid item xs={12}></Grid>

                        <Grid item xs={0.5}></Grid>

                        <Grid item xs={3.5}>
                            {item.reference}
                        </Grid>

                        <Grid item xs={2}>
                            {item.amount}
                        </Grid>

                        <Grid item xs={3}>
                            {item.mandate ? (item.mandate?.firstName + ' ' + item.mandate?.surname)
                                : ''}
                        </Grid>

                        <Grid item xs={0.5}>
                            <input name={Failed.name} type="checkbox" onChange={(e) => onPaymentIntentsChange(e, item.id)} />
                        </Grid>

                        <Grid item xs={0.5}></Grid>

                        <Grid item xs={0.5}>
                            <input name={Regenerate.name} type="checkbox" onChange={(e) => onRegenerateChange(e, item.id)}
                                disabled={paymentIntents?.find(x => x.paymentIntentId === item.id)?.paid} />
                        </Grid>

                        <Grid item xs={1.5}></Grid>

                        <Grid item xs={12}></Grid>

                    </Grid>
                ))}

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box component={Paper} sx={{ padding: 3 }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={1}></Grid>

                <Grid item xs={1.5}>
                    <Button variant="outlined" onClick={() => {
                        history.push(`/direct-debit-run/${directDebitRunId}`);
                    }}>
                        Cancel
                    </Button>
                </Grid>

                <Grid item xs={1.5}>
                    <Button variant="contained" onClick={submitPaymentIntents}>
                        Submit
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>

            </Grid>
        </Box>
    </FormProvider>
}
