import LoadingComponent from "../../app/components/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { Box, Paper, Grid, Typography } from "@mui/material";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { useAppSelector } from "../../app/store/configureStore";
import { formatDateWithTime } from "../../app/utils/util";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DirectDebitRunDto } from "../../app/models/directDebitRun/directDebitRunDto";
import { useGetDirectDebitsRun } from "../../app/customHooks/DirectDebitRun/useGetDirectDebitsRun";
import { useGetReferenceData } from "../../app/customHooks/ReferenceData/useGetReferenceData";
import DirectDebitRunFormModel from "./common/directDebitRunFormModel";

export default function GetDirectDebitRunDetails() {
    const {
        formField: {
            Reference,
            CreditorName,
            CreatedDate,
            CreatedBy,
            CollectionDueDate
        }
    } = DirectDebitRunFormModel;

    const { directDebitRunId } = useParams<{ directDebitRunId: string }>();

    useGetReferenceData();
    useGetDirectDebitsRun();

    const { creditors, referenceDataStatus } = useAppSelector(state => state.referenceData);
    const { directDebitsRun, directDebitsRunStatus } = useAppSelector(state => state.directDebitRun);

    const [directDebitRun, setDirectDebitRun] = useState<DirectDebitRunDto>();

    useEffect(() => {
        if (directDebitRunId !== '' && directDebitsRun && directDebitsRun?.length > 0) {
            setDirectDebitRun(directDebitsRun.find(x => x.id === directDebitRunId));
        }
    }, [directDebitsRun]);

    if (directDebitsRunStatus === ApiRequestStatus.Pending ||
        referenceDataStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <Box component={Paper} sx={{ padding: 3 }}>
        <Grid container rowSpacing={4}>

            <Grid item xs={12}>
                <Typography variant='h4'>Active direct debit run</Typography>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={3}>
                <Typography variant='body1'><b>{Reference.label}</b></Typography>
            </Grid>

            <Grid item xs={3}>
                <Typography variant='body2'>{directDebitRun?.reference}</Typography>
            </Grid>

            <Grid item xs={5}></Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={3}>
                <Typography variant='body1'><b>{CreditorName.label}</b></Typography>
            </Grid>

            <Grid item xs={3}>
                <Typography variant='body2'>{creditors?.find(x => x.id === directDebitRun?.directDebitCreditorId)?.value}</Typography>
            </Grid>

            <Grid item xs={5}></Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={3}>
                <Typography variant='body1'><b>{CreatedDate.label}</b></Typography>
            </Grid>

            <Grid item xs={3}>
                <Typography variant='body2'>{directDebitRun?.createdDate && formatDateWithTime(new Date(directDebitRun?.createdDate))}</Typography>
            </Grid>

            <Grid item xs={5}></Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={3}>
                <Typography variant='body1'><b>{CreatedBy.label}</b></Typography>
            </Grid>

            <Grid item xs={3}>
                <Typography variant='body2'>{directDebitRun?.createdby}</Typography>
            </Grid>

            <Grid item xs={5}></Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={3}>
                <Typography variant='body1'><b>{CollectionDueDate.label}</b></Typography>
            </Grid>

            <Grid item xs={3}>
                <Typography variant='body2'>
                    {directDebitRun?.collectionDueDate && formatDateWithTime(new Date(directDebitRun?.collectionDueDate))}
                </Typography>
            </Grid>

            <Grid item xs={5}></Grid>

            <Grid item xs={12}></Grid>

        </Grid>
    </Box>
}
