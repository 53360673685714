import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { deleteFileAsync, downloadFileAsync, getFilesAsync } from "../../app/slices/documentSlice";
import { FileDto } from "../../app/models/referenceData/fileDto";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import LoadingComponent from "../../app/components/LoadingComponent";
import { Paper, Button, Box, Grid } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { API_REQUEST_FULLFILLED } from "../../app/utils/constant";
import { toast } from "react-toastify";
import UploadFile from "./UploadFile";
import { documentTypesAsync } from "../../app/slices/referenceDataSlice";
import React from "react";
import { formatBytes, formatDateWithTime } from "../../app/utils/util";

export default function FileList() {
    const queryParams = new URLSearchParams(window.location.search);
    const containerId = queryParams.get("containerId");
    const personId = queryParams.get("personId");
    const entityType = queryParams.get("entityType");

    const dispatch = useAppDispatch();

    const { files, deleteStatus, downloadStatus, uploadStatus } =
        useAppSelector(state => state.document);

    const { documentTypes } = useAppSelector(state => state.referenceData);

    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        if (files === null && containerId !== undefined && containerId !== null) {
            setPageLoading(true);

            const allPromise = async () => await Promise.all([
                dispatch(getFilesAsync(containerId)),
                dispatch(documentTypesAsync())
            ]);

            allPromise().finally(() => {
                setPageLoading(false);
            });
        }
    }, [dispatch, files]);

    const downloadFile = (id: string, name: string | null) => {
        if (name !== null) {
            dispatch(downloadFileAsync({ id: id, fileName: name })).then((response: any) => {
                const url = window.URL.createObjectURL(new Blob([response.payload], { type: 'application/octet-stream' }));
                const link = document.createElement('a');

                link.href = url;
                link.download = response.meta.arg.fileName;

                document.body.appendChild(link);

                link.click();
                link.remove();

                window.URL.revokeObjectURL(url);
            });
        }
    }

    const deleteFile = (id: string, name: string | null) => {
        if (name !== null && containerId !== undefined && containerId !== null) {
            dispatch(deleteFileAsync({ id: id, fileName: name })).then((response) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getFilesAsync(containerId));
                    toast.success('File deleted successfully.');
                }
            });
        }
    }

    if (pageLoading === true ||
        uploadStatus === ApiRequestStatus.Pending ||
        deleteStatus === ApiRequestStatus.Pending ||
        downloadStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message='Processing details...' />
    }

    return (
        <>
            {containerId && personId && entityType && documentTypes &&
                <UploadFile containerId={containerId} personId={personId} entityType={entityType}
                    documentTypes={documentTypes} files={files} />}

            <Box sx={{ flexGrow: 1 }} component={Paper}>
                <Grid container component={Paper} sx={{
                    backgroundColor: '#616161',
                    color: "#fff",
                    padding: 2,
                    textAlign: 'left'
                }}>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={4}>
                        File name
                    </Grid>

                    <Grid item xs={2}>
                        Document type
                    </Grid>

                    <Grid item xs={1}>
                        File size
                    </Grid>

                    <Grid item xs={2}>
                        Modified date
                    </Grid>

                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>

                </Grid>

                {(files === undefined || files == null || files?.length === 0) &&
                    <Grid container component={Paper} sx={{
                        textAlign: 'left',
                        alignItems: 'center'
                    }}>

                        <Grid item xs={12}></Grid>
                        <br />

                        <Grid item xs={12} sx={{
                            textAlign: 'center'
                        }}>
                            No documents found.
                        </Grid>

                        <Grid item xs={12}></Grid>
                        <br />

                    </Grid>
                }

                {files?.map((item: FileDto, index: number) => (
                    <React.Fragment key={index}>
                        <Grid container component={Paper} sx={{
                            textAlign: 'left',
                            alignItems: 'center'
                        }}>

                            <Grid item xs={12}></Grid>

                            <Grid item xs={1}></Grid>

                            <Grid item xs={4}>
                                {`${item.name}`}
                            </Grid>

                            <Grid item xs={2}>
                                {item.tags !== null &&
                                    documentTypes?.find(x => x.code === item.tags?.FILE_TYPE)?.value}
                            </Grid>

                            <Grid item xs={1}>
                                {item.size && item.size !== null && formatBytes(item.size)}
                            </Grid>

                            <Grid item xs={2}>
                                {item.modifiedDate && item.modifiedDate !== null &&
                                    formatDateWithTime(new Date(item.modifiedDate))}
                            </Grid>

                            <Grid item xs={1} sx={{ padding: 1 }}>
                                <Button variant="outlined" startIcon={<DeleteIcon />} sx={{ paddingRight: '1px' }}
                                    onClick={() => containerId !== undefined && containerId !== null &&
                                        deleteFile(containerId, item.name)}>
                                </Button>
                            </Grid>

                            <Grid item xs={1} sx={{ padding: 1 }}>
                                <Button variant="outlined" startIcon={<DownloadIcon />} sx={{ paddingRight: '1px' }}
                                    onClick={() => containerId !== undefined && containerId !== null &&
                                        downloadFile(containerId, item.name)}>
                                </Button>
                            </Grid>

                        </Grid>
                    </React.Fragment>
                ))}

            </Box>
        </>
    );
}
