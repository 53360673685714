import { DirectDebitTypeEnum } from "../enums/directDebitTypeEnum";
import { DropdownItem } from "../models/common/dropdownItem";
import { Currency } from "../models/referenceData/currency";
import { DocumentType } from "../models/referenceData/documentType";
import { FundDto } from "../models/manualPayment/fundDto";
import { PaymentMethodDto } from "../models/manualPayment/paymentMethodDto";
import { ProductDto } from "../models/referenceData/productDto";

export function MapDocumentTypesToDropdownList(domainTypes: DocumentType[]) {
    let domainTypeList: DropdownItem[] = [];

    domainTypes?.map((item: DocumentType) => {
        domainTypeList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return domainTypeList;
}


export function MapProductsToDropdownItems(products: ProductDto[]) {
    let productList: DropdownItem[] = [];

    products?.map((item: ProductDto) => {
        productList.push({
            id: item.code ?? '',
            value: item.name ?? '',
            code: item.code ?? ''
        });
        return true;
    });

    return productList;
}

export function MapPaymentMethodsToDropdownItems(paymentMethods: PaymentMethodDto[]) {
    let paymentMethodList: DropdownItem[] = [];

    paymentMethods?.map((item: PaymentMethodDto) => {
        paymentMethodList.push({
            id: item.id,
            value: item.name,
            code: item.code ?? ''
        });
        return true;
    });

    return paymentMethodList;
}

export function MapFundsToDropdownItems(funds: FundDto[] | undefined) {

    if (!funds) return [];

    let fundList: DropdownItem[] = [];

    funds?.map((item: FundDto) => {
        fundList.push({
            id: item.id ?? '',
            value: item.fundname ?? '',
            code: item.code ?? ''
        });
        return true;
    });

    return fundList;
}

export function MapCurrenciesToDropdownItems(currencies: Currency[]) {
    let currencyList: DropdownItem[] = [];

    currencies?.map((item: Currency) => {
        currencyList.push({
            id: item.id ?? '',
            value: item.name ?? '',
            code: item.code ?? ''
        });
        return true;
    });

    return currencyList.sort((a, b) => a.value > b.value ? 1 : -1);
}

export function MapTypeOfDirectDebitsToDropdownItems() {
    const typeOfDirectDebits: DropdownItem[] = [
        {
            id: DirectDebitTypeEnum.RECURRENT,
            value: DirectDebitTypeEnum.RECURRENT
        },
        {
            id: DirectDebitTypeEnum.ONCE_OFF,
            value: DirectDebitTypeEnum.ONCE_OFF
        }
    ];

    return typeOfDirectDebits;
}

export function MapPaymentFrequencyToDropdownItems() {
    const paymentFrequencies: DropdownItem[] = [
        {
            id: 'Monthly',
            value: 'Monthly'
        },
        {
            id: 'Every3Months',
            value: 'Every 3 months'
        },
        {
            id: 'Yearly',
            value: 'Yearly'
        }
    ];

    return paymentFrequencies;
}
