const DirectDebitRunFormModel = {
  formField: {
    Reference: {
      name: 'Reference',
      label: 'Reference'
    },
    CreditorName: {
      name: 'CreditorName',
      label: 'Creditor name'
    },
    CreatedDate: {
      name: 'CreatedDate',
      label: 'Created date'
    },
    CreatedBy: {
      name: 'CreatedBy',
      label: 'Created by'
    },
    ReturnDate: {
      name: 'ReturnDate',
      label: 'Return date',
      requiredErrorMsg: 'Return date is required'
    },
    CollectionDueDate: {
      name: 'CollectionDueDate',
      labelRequired: 'Collection due date*',
      label: 'Collection due date',
      requiredErrorMsg: 'Collection date is required'
    },
    NumberOfPaymentIntents: {
      name: 'NumberOfPaymentIntents',
      label: 'Number of payment intents'
    },
    Failed: {
      name: 'Failed',
      label: 'Failed'
    },
    Regenerate: {
      name: 'Regenerate',
      label: 'Regenerate'
    }
  }
};

export default DirectDebitRunFormModel;
