import { useEffect, useState } from "react";
import { PaymentTabEnum } from "../../app/enums/paymentTabEnum";
import ManualPaymentForm from "../ManualPayment/ManualPaymentForm";
import DirectDebitForm from "../DirectDebit/DirectDebitForm";
import { Paper, Grid, Tabs, Tab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useForm } from "react-hook-form";

export function Payments() {
    const tabs = ['Manual payment', 'Direct debit mandate'];

    function getStepContent(step: string) {
        switch (step) {
            case PaymentTabEnum.Payment:
                return <ManualPaymentForm />
            case PaymentTabEnum.DirectDebitMandate:
                return <DirectDebitForm />
            default:
                throw new Error('Unknown step');
        }
    }

    const methods = useForm();
    const { watch } = methods;

    const [activeStep, setActiveStep] = useState(0);
    const [expectedStep, setExpectedStep] = useState(0);
    const [arePageValuesChanged, setArePageValuesChanged] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        watch((value, { type }) => {
            if (type === 'change') {
                setArePageValuesChanged(true);
            }
        });
    }, [watch]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (arePageValuesChanged === true) {
            setShowDialog(true);
            setExpectedStep(newValue);
        }
        else {
            setActiveStep(newValue);
        }
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    const handleCloseAndContinue = () => {
        setShowDialog(false);
        setArePageValuesChanged(false);
        setActiveStep(expectedStep);
    };

    return <>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Grid item xs={12}></Grid>

            <Tabs value={activeStep}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
                sx={{
                    '& .MuiTabs-flexContainer': {
                        flexWrap: 'wrap',
                    },
                }}>
                {tabs.map((label: string, inedex: number) => (
                    <Tab key={label} value={inedex} label={label} />
                ))}
            </Tabs>
            {getStepContent(tabs[activeStep])}
        </Paper>

        <Dialog
            open={showDialog}
            onClose={handleClose}
            aria-labelledby="unsaved-changes-dialog"
            aria-describedby="unsaved-changes-dialog"
        >
            <DialogTitle id="unsaved-changes-dialog">
                {"Unsaved changes?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="unsaved-changes-dialog">
                    This page contains unsaved changes. Do you still wish to leave this page?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button variant="contained" color="primary"
                    onClick={handleCloseAndContinue} autoFocus>
                    Yes
                </Button>
                <Button onClick={handleClose}>No</Button>
            </DialogActions>
        </Dialog>
    </>
}
