import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { CreateDonationDirectDebitMandateCommand } from "../models/directDebit/createDonationDirectDebitMandateCommand";
import { CreateFamDirectDebitMandateCommand } from "../models/directDebit/createFamDirectDebitMandateCommand";

interface directDebitState {
    reference: string | null;
    submitDonationDirectDebitDetailsStatus: ApiRequestStatus;
    submitFamDirectDebitDetailsStatus: ApiRequestStatus;
}

const initialState: directDebitState = {
    reference: null,
    submitDonationDirectDebitDetailsStatus: ApiRequestStatus.Idle,
    submitFamDirectDebitDetailsStatus: ApiRequestStatus.Idle
}

export const submitDonationDirectDebitAsync = createAsyncThunk<string, CreateDonationDirectDebitMandateCommand>(
    'directDebitMandateSlice/submitDonationDirectDebitAsync',
    async (createDonationDirectDebitMandateCommand, thunkAPI) => {
        try {
            return agent.directDebit.submitDonationDirectDebitAsync(createDonationDirectDebitMandateCommand);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const submitFamDirectDebitAsync = createAsyncThunk<string, CreateFamDirectDebitMandateCommand>(
    'directDebitMandateSlice/submitFamDirectDebitAsync',
    async (createFamDirectDebitMandateCommand, thunkAPI) => {
        try {
            return agent.directDebit.submitFamDirectDebitAsync(createFamDirectDebitMandateCommand);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const directDebitMandateSlice = createSlice({
    name: 'directDebitMandateSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(submitDonationDirectDebitAsync.pending, (state) => {
            state.submitDonationDirectDebitDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitDonationDirectDebitAsync.fulfilled, (state, action) => {
            state.reference = action.payload;
            state.submitDonationDirectDebitDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitDonationDirectDebitAsync.rejected, (state) => {
            state.submitDonationDirectDebitDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(submitFamDirectDebitAsync.pending, (state) => {
            state.submitFamDirectDebitDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitFamDirectDebitAsync.fulfilled, (state, action) => {
            state.reference = action.payload;
            state.submitFamDirectDebitDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitFamDirectDebitAsync.rejected, (state) => {
            state.submitFamDirectDebitDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})
