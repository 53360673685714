import LoadingComponent from "../../app/components/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { DirectDebitRunDto } from "../../app/models/directDebitRun/directDebitRunDto";
import { formatDateWithTime } from "../../app/utils/util";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { getDirectDebitsRunAsync } from "../../app/slices/directDebitRunSlice";
import { referenceDataAsync } from "../../app/slices/referenceDataSlice";
import { getDirectDebitPaymentIntentsAsync } from "../../app/slices/directDebitPaymentIntentSlice";
import DirectDebitRunFormModel from "./common/directDebitRunFormModel";

export default function DirectDebitRunList() {
    const {
        formField: {
            Reference,
            CreditorName,
            CreatedDate,
            NumberOfPaymentIntents
        }
    } = DirectDebitRunFormModel;

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(referenceDataAsync());
        dispatch(getDirectDebitsRunAsync());
        dispatch(getDirectDebitPaymentIntentsAsync());
    }, [dispatch]);

    const history = useHistory();

    const { creditors, referenceDataStatus } = useAppSelector(state => state.referenceData);
    const { directDebitsRun, directDebitsRunStatus } = useAppSelector(state => state.directDebitRun);

    if (directDebitsRunStatus === ApiRequestStatus.Pending ||
        referenceDataStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <Box component={Paper} sx={{ padding: 3 }}>
        <Grid container rowSpacing={4}>

            <Grid item xs={12}>
                <Typography variant='h4'>Active direct debit runs</Typography>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid container sx={{
                backgroundColor: '#616161',
                color: "#fff",
                padding: 2,
                textAlign: 'left'
            }}>
                <Grid item xs={0.5}></Grid>

                <Grid item xs={3.5}>
                    {Reference.label}
                </Grid>

                <Grid item xs={2}>
                    {CreatedDate.label}
                </Grid>

                <Grid item xs={3}>
                    {CreditorName.label}
                </Grid>

                <Grid item xs={2}>
                    {NumberOfPaymentIntents.label}
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>

            {(directDebitsRun === undefined || directDebitsRun == null || directDebitsRun?.length === 0) &&
                <Grid container sx={{
                    textAlign: 'left',
                    alignItems: 'center'
                }} rowSpacing={2}>

                    <Grid item xs={12}></Grid>
                    <br />

                    <Grid item xs={12} sx={{
                        textAlign: 'center'
                    }}>
                        No direct debits run found.
                    </Grid>

                    <Grid item xs={12}></Grid>

                </Grid>
            }

            {directDebitsRun?.map((item: DirectDebitRunDto, index: number) => (
                <Grid container key={index} sx={{
                    textAlign: 'left',
                    alignItems: 'center'
                }} rowSpacing={0.5}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={0.5}></Grid>

                    <Grid item xs={3.5}>
                        {item.reference}
                    </Grid>

                    <Grid item xs={2}>
                        {formatDateWithTime(new Date(item.createdDate))}
                    </Grid>

                    <Grid item xs={3}>
                        {creditors?.find(x => x.id === item.directDebitCreditorId)?.value}
                    </Grid>

                    <Grid item xs={2} sx={{ textAlign: 'center' }}>
                        {item.paymentIntents.length}
                    </Grid>

                    <Grid item xs={1}>
                        <Button variant="outlined" onClick={() => {
                            history.push(`/direct-debit-run/${item.id}`);
                        }}>
                            View
                        </Button>
                    </Grid>

                </Grid>
            ))}

            <Grid item xs={12}></Grid>

        </Grid>
    </Box>
}
