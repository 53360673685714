import { FormProvider, useForm } from "react-hook-form";
import DirectDebitRunList from "./DirectDebitRunList";
import CreditorList from "./CreditorList";
import PaymentIntentActions from "./PaymentIntentActions";
import { Box, Paper, Grid, Typography } from "@mui/material";

export default function DirectDebitRunForm() {

    const methods = useForm({ mode: 'all' });

    return <FormProvider {...methods}>
        <Box component={Paper} sx={{ padding: 3 }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}>
                    <Typography variant='h3' sx={{ textAlign: 'center' }}>Manage direct debits</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
        <DirectDebitRunList />
        <CreditorList />
        <PaymentIntentActions />
    </FormProvider>
}
