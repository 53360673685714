import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { DirectDebitRunDto } from "../models/directDebitRun/directDebitRunDto";
import { ReconcileDirectDebitRunCommand } from "../models/directDebitRun/reconcileDirectDebitRunCommand";
import { CreateDirectDebitRunCommand } from "../models/directDebitRun/createDirectDebitRunCommand";

interface directDebitState {
    directDebitsRun: DirectDebitRunDto[] | null;
    directDebitsRunStatus: ApiRequestStatus;

    directDebitRun: DirectDebitRunDto | null;
    directDebitRunStatus: ApiRequestStatus;

    directDebitRunFileStatus: ApiRequestStatus;

    hasDirectDebitRunDeleted: boolean | null;
    deleteDirectDebitRunStatus: ApiRequestStatus;

    hasDirectDebitRunReconciled: boolean | null;
    reconcileDirectDebitRunStatus: ApiRequestStatus;

    recordId: string | null;
    submitDirectDebitRunStatus: ApiRequestStatus;
}

const initialState: directDebitState = {
    directDebitsRun: null,
    directDebitsRunStatus: ApiRequestStatus.Idle,

    directDebitRun: null,
    directDebitRunStatus: ApiRequestStatus.Idle,

    directDebitRunFileStatus: ApiRequestStatus.Idle,

    hasDirectDebitRunDeleted: null,
    deleteDirectDebitRunStatus: ApiRequestStatus.Idle,

    hasDirectDebitRunReconciled: null,
    reconcileDirectDebitRunStatus: ApiRequestStatus.Idle,

    recordId: null,
    submitDirectDebitRunStatus: ApiRequestStatus.Idle
}

export const getDirectDebitsRunAsync = createAsyncThunk<DirectDebitRunDto[]>(
    'directDebitRunSlice/getDirectDebitsRunAsync',
    async (_, thunkAPI) => {
        try {
            return agent.directDebitRun.getDirectDebitsRunAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDirectDebitRunByIdAsync = createAsyncThunk<DirectDebitRunDto, string>(
    'directDebitRunSlice/getDirectDebitRunByIdAsync',
    async (id, thunkAPI) => {
        try {
            return agent.directDebitRun.getDirectDebitRunByIdAsync(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDirectDebitRunFileAsync = createAsyncThunk<DirectDebitRunDto[], string>(
    'directDebitRunSlice/getDirectDebitRunFileAsync',
    async (id, thunkAPI) => {
        try {
            return agent.directDebitRun.getDirectDebitRunFileAsync(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const deleteDirectDebitRunByIdAsync = createAsyncThunk<boolean, string>(
    'directDebitRunSlice/deleteDirectDebitRunByIdAsync',
    async (id, thunkAPI) => {
        try {
            return agent.directDebitRun.deleteDirectDebitRunByIdAsync(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const reconcileDirectDebitRunAsync = createAsyncThunk<boolean, ReconcileDirectDebitRunCommand>(
    'directDebitRunSlice/reconcileDirectDebitRunAsync',
    async (reconcileDirectDebitRunCommand, thunkAPI) => {
        try {
            return agent.directDebitRun.reconcileDirectDebitRunAsync(reconcileDirectDebitRunCommand);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const submitDirectDebitRunAsync = createAsyncThunk<string, CreateDirectDebitRunCommand>(
    'directDebitRunSlice/submitDirectDebitRunAsync',
    async (createDirectDebitRunCommand, thunkAPI) => {
        try {
            return agent.directDebitRun.submitDirectDebitRunAsync(createDirectDebitRunCommand);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const directDebitRunSlice = createSlice({
    name: 'directDebitRunSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getDirectDebitsRunAsync.pending, (state) => {
            state.directDebitsRunStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getDirectDebitsRunAsync.fulfilled, (state, action) => {
            state.directDebitsRun = action.payload;
            state.directDebitsRunStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getDirectDebitsRunAsync.rejected, (state) => {
            state.directDebitsRunStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getDirectDebitRunByIdAsync.pending, (state) => {
            state.directDebitRunStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getDirectDebitRunByIdAsync.fulfilled, (state, action) => {
            state.directDebitRun = action.payload;
            state.directDebitRunStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getDirectDebitRunByIdAsync.rejected, (state) => {
            state.directDebitRunStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getDirectDebitRunFileAsync.pending, (state) => {
            state.directDebitRunFileStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(getDirectDebitRunFileAsync.fulfilled, (state, action) => {
            state.directDebitRunFileStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getDirectDebitRunFileAsync.rejected, (state) => {
            state.directDebitRunFileStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(deleteDirectDebitRunByIdAsync.pending, (state) => {
            state.deleteDirectDebitRunStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(deleteDirectDebitRunByIdAsync.fulfilled, (state, action) => {
            state.hasDirectDebitRunDeleted = action.payload;
            state.deleteDirectDebitRunStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(deleteDirectDebitRunByIdAsync.rejected, (state) => {
            state.deleteDirectDebitRunStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(reconcileDirectDebitRunAsync.pending, (state) => {
            state.reconcileDirectDebitRunStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(reconcileDirectDebitRunAsync.fulfilled, (state, action) => {
            state.hasDirectDebitRunReconciled = action.payload;
            state.reconcileDirectDebitRunStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(reconcileDirectDebitRunAsync.rejected, (state) => {
            state.reconcileDirectDebitRunStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(submitDirectDebitRunAsync.pending, (state) => {
            state.submitDirectDebitRunStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitDirectDebitRunAsync.fulfilled, (state, action) => {
            state.recordId = action.payload;
            state.submitDirectDebitRunStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitDirectDebitRunAsync.rejected, (state) => {
            state.submitDirectDebitRunStatus = ApiRequestStatus.Rejected;
        });
    })
})
