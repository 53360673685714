import * as yup from 'yup';
import DirectDebitRunFormModel from './directDebitRunFormModel';

const {
  formField: {
    ReturnDate
  }
} = DirectDebitRunFormModel;

export const ReconcileDDRunValidationSchemas = yup.object().shape({
  [ReturnDate.name]: yup.string().required(`${ReturnDate.requiredErrorMsg}`)
});
